@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Inter', 'sans-serif';
    background-color: #F9FAFB;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

//----------------------------Common styles-------------------------
.primary-font {
    @apply text-sm text-palette-gray-700;
}
.primary-font-bold {
    @apply text-sm text-palette-gray-900 font-semibold;
}
//----------------------------Custom scroll-------------------------
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

//*****************************************************
//*************************Login **************************
.flex-container {
    display: flex;
    flex: 90%;
    align-items: center;
}

.login-container {
    @apply flex flex-col w-full gap-5 mt-8;
    .login-username-input {
        @apply w-full min-h-[56px] border rounded-lg;
    }
    .sign-in-btn {
        @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
        .p-button:hover {
            @apply bg-inherit;
        }
    }
    .sign-in-btn:hover {
        @apply bg-branding-primary-600;
    }
    .sign-in-btn:focus {
        box-shadow: none !important;
    }
    .p-inputtext:focus {
        @apply border-branding-primary-300 outline-none;
        box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .p-inputtext:hover {
        @apply border-branding-primary-300;
    }
    .p-button.p-button-secondary:enabled:focus {
        box-shadow: unset;
    }
    .login-phone-dropdown {
        @apply ml-2.5;
        .p-dropdown-panel {
            @apply w-[260px] bg-white;
        }
        .p-overlay {
            @apply mt-4 ml-[-12px];
        }
        .p-dropdown {
            @apply flex items-baseline;
        }
        .p-component {
            @apply border-none h-10;
        }
        .p-dropdown-items-wrapper {
            @apply bg-white text-sm min-h-[240px] border-system-gray-200;
        }
    }
    .password-input::placeholder {
        @apply text-xs text-system-gray-500;
    }
    .password-input::-ms-reveal {
        @apply hidden;
    }
    .login-phone-input {
        @apply border-none w-full;
    }
}

//************************************************************
//***************************Sign up*********************************
.background {
    @apply h-auto bg-cover object-contain flex;
    background-image: url('/assets/images/Background.svg');
}

.container {
    @apply hidden;
}

.sign-up-flex-container {
    @apply hidden;
}

.sign-up-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.flex-container {
    display: flex;
    flex: 90%;
    align-items: center;
    justify-content: flex-end;
}

.sign-up-password-input::placeholder {
    @apply text-xs text-system-gray-400;
}

.sign-up-password-input::-ms-reveal {
    @apply hidden;
}

.area-input {
    @apply border-none w-full;
}

.verification-form {
    @apply h-12 flex flex-row justify-start items-start gap-4 p-0;
    .p-inputtext::placeholder {
        @apply text-center text-base;
    }
}

.verification-input {
    @apply h-12 w-14 text-center font-bold;
}

.resend-button {
    @apply bg-white border-none cursor-pointer font-bold text-branding-primary-700;
}

.sign-up-btn {
    @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;
    .p-button:hover {
        @apply bg-inherit;
    }
    .p-button:enabled:active {
        @apply border-transparent bg-branding-primary-700;
    }
}

.p-inputtext {
    @apply w-full min-h-[48px] rounded-lg;
}

.p-inputtext:focus {
    border-color: #84caff;
    outline: none;
    box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.p-inputtext:hover {
    @apply border-branding-primary-300;
}

.sign-up-form {
    @apply flex flex-col gap-4 mt-5;
    .policy-checkbox {
        .p-checkbox .p-checkbox-box {
            @apply rounded-lg;
        }
    }
    .area-dropdown {
        @apply ml-2.5;
        .p-dropdown-panel {
            @apply w-[260px] bg-white;
        }
        .p-overlay {
            @apply mt-4 ml-[-12px];
        }
        .p-dropdown {
            @apply flex items-center;
        }
        .p-component {
            @apply border-none h-10;
        }
        .p-dropdown-items-wrapper {
            @apply bg-white text-sm min-h-[240px] border-system-gray-200;
        }
    }
    .flex-input {
        @apply flex flex-col gap-1;
        input {
            @apply w-full;
        }
    }
    .p-inputtext:focus {
        border-color: #84caff;
        outline: none;
        box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .p-inputtext:hover {
        @apply border-branding-primary-300;
    }
}

@media (min-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
    .sign-up-container {
        flex: 1.25;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}
// **********************************************************
// ------------------------- Toast --------------------------
$bg_error: #FFFBFA;
$bd_error: #FDA29B;
$color_error: #B42318;
$bg_warning: #FFFCF5;
$bd_warning: #FEC84B;
$color_waring: #B54708;
$bg_success: #F6FEF9;
$bd_success: #6CE9A6;
$color_success: #027A48;
$bg_info: #FFF;
$bd_info: #D0D5DD;
$color_info: #344054;
$close_icon_error: '/assets//images//common-icon//error-close.svg';
$close_icon_warning: '/assets//images//common-icon//warning-close.svg';
$close_icon_success: '/assets//images//common-icon//success-close.svg';
$close_icon_info: '/assets//images//common-icon//info-close.svg';
.toast-booking {
    .p-toast {
        font-family: Inter !important;
        font-weight: 700 !important;
        opacity: 1 !important;
        top: 5.75rem;
        width: 29rem;
        right: 1.5rem;
        max-height: 17rem !important;
        overflow: hidden;
    }
    .p-toast-message-error {
        border: 1px solid $bd_error !important;
        background-color: $bg_error !important;
        opacity: 1 !important;
        color: $color_error !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_error) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-warning {
        color: $color_waring !important;
        background-color: $bg_warning !important;
        border: 1px solid $bd_warning !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_warning) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-success {
        color: $color_success !important;
        background-color: $bg_success !important;
        border: 1px solid $bd_success !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_success) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-toast-message-info {
        color: $color_info !important;
        background-color: $bg_info !important;
        border: 1px solid $bd_info !important;
        border-radius: 12px !important;
        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_info) !important;
            background-repeat: no-repeat !important;
        }
    }
    .p-link:focus {
        box-shadow: none !important;
    }
    .p-toast-icon-close {
        height: 100% !important;
    }
    .p-toast-message-content {
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
    }
    .p-toast-message {
        display: flex !important;
        box-shadow: none !important;
        border-radius: none !important;
        transition: none !important;
        height: fit-content !important;
        white-space: pre-wrap !important;
        width: 100%;
        padding: 1rem !important;
    }
    .p-toast-icon-close:hover {
        background: none !important;
    }
    .p-toast-icon-close-icon::before {
        content: none !important;
    }
    .toast-content {
        height: fit-content;
        line-height: 1.25rem;
        width: 100%;
        margin: 0 0.75rem;
        font-size: 0.875rem;
        white-space: pre-wrap;
    }
}

.ota-location-form {
    .p-inputtext {
        min-height: unset;
    }

    .p-dropdown .p-dropdown-label {
        color: #344054;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
    }
    p-dropdown.p-element.p-inputwrapper {
        height: 44px;
    }
    .p-dropdown.p-component {
        min-width: 100%;
        min-height: unset;
        height: 44px;
        &.p-disabled {
            background-color: #EAECF0 !important;
            opacity: 1 !important;
    
            .p-dropdown-label.p-placeholder {
                opacity: .6 !important;
            }
        }
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
        margin: 0 !important;
        height: 44px;
        min-height: unset;
        width: 1rem;
        color: #98A2B3;
    }
    .p-dropdown .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 44px;
        height: 44px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054 !important;
      }
    .p-dropdown-trigger {
        width: 3rem !important;
        margin: 0 !important;
        padding: 12px 8px 12px 0;
        .pi.pi-chevron-down {
            font-size: 20px;
        }
    }
    .distance-field {
        .is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }
        span.p-inputnumber.p-component {
            width: 100%;
            height: 44px;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-inputnumber-input {
            min-height: unset;
            border-radius: 0.5rem 0 0 0.5rem;
            &:hover {
                border-color:#84caff !important;
            }
        }
        .p-dropdown.p-component {
            border-radius: 0 0.5rem 0.5rem 0;
            height: 44px !important;
        }
    }
}


/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/fonts';
@import 'styles/theme';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import 'styles/primeng-custom';
@import 'styles/svg';
  
.admin-custom {
    .dropdown-custom {
        .p-dropdown.p-component {
            height: 44px;
            min-height: 44px;
            width: 100%;
            border-radius: 8px;
            &:hover {
                border: 1px solid #84caff;
            }
            &.p-focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }

            .p-dropdown-label {
                font-size: 14px;
            }
            .p-inputtext {
                min-height: 44px;
                padding: 12px 4px 12px 16px;
            }

            .p-dropdown-trigger {
                .p-dropdown-trigger-icon {
                    padding-right: 0;
                    color: #667085;
                }
            }
            .p-dropdown-items {
                padding: 4px 0;
            }
            .p-dropdown-item {
                font-size: 14px;
                color: #344054;
                font-size: 14px;
                &:hover {
                    background-color: #eff8ff;
                }
            }

            .p-dropdown-items-wrapper .p-dropdown-item.p-highlight {
                background-color: transparent;
                &:hover {
                    background-color: #eff8ff;
                }
            }
        }
    }
    .search-custom {
        .p-inputtext.p-component.p-element {
            min-height: 44px;
            height: 44px;
            &:hover {
                border: 1px solid #84caff;
            }
            &:focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
            &.is-invalid {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                    border: 1px solid #dc3545;
                }
            }
            &.is-disabled {
                background-color: #F9FAFB;
                &:hover {
                    border: 1px solid #ced4da !important;
                }
            }
        }
        .custom-padding {
            &.p-inputtext {
                padding: 12px 12px 12px 32px !important;
            }
        }
        &.fee-custom {
            .p-inputtext  {
                padding: 12px 58px 12px 32px !important;
            }
        }
        .p-disabled, .p-component:disabled {
            background-color: #F9FAFB;
            &:hover {
                border: 1px solid #ced4da !important;
            }
        }
    }
    .autocomplete-custom {
        .p-autocomplete-items {
            padding: 4px;
            .p-autocomplete-item {
                border-radius: 4px;
            }
        }
        .p-autocomplete.p-component {
            width: 100%;
        }

        .has-icon {
            .p-autocomplete-input {
                padding: 12px 12px 12px 40px;
            }
        }

        .is-invalid {
            .p-autocomplete-input {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
                    border: 1px solid #dc3545 !important;
                }
                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }
        }
        
        .font-sm {
            .p-autocomplete-input {
                color: #344054;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .calendar-custom {
        .p-calendar {
            width: 100%;
        }
        .p-inputtext.p-component {
            min-height: 44px;
            height: 44px;
            &:hover {
                border: 1px solid #84caff;
            }
            &:focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
        }
        .p-datepicker table td > span.p-highlight  {
            color: #1570ef;
            background: #eff8ff;
        }
        .p-datepicker-month:hover, .p-datepicker-year:hover {
            color: #1570ef !important;
        }
        .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus, 
        .p-monthpicker-month:focus,
        .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
        .p-datepicker table td > span:focus {
            box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff !important;
        }
        .p-datepicker {
            .p-timepicker {
                padding: 0;
                
                .p-hour-picker, .p-minute-picker {
                    span {
                        font-size: 14px !important;
                        font-weight: 600;
                    }
                    .p-ripple.p-element {
                        width: 28px;
                        height: 28px;
                        
                        span {
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .font-sm {
            .p-inputtext.p-component {
                color: #344054;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .is-invalid .p-inputtext.p-component {
            border: 1px solid #dc3545;
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                border: 1px solid #dc3545;
            }
            &:hover {
                border: 1px solid #dc3545;
            }
        }

        &.custom-1 {
            .p-datepicker.p-component {
                bottom: unset !important;
                top: 44px !important;
            }
            @media (max-width: 1536px) {
                .p-datepicker.p-component {
                    max-width: 360px;
                }
                .p-datepicker-calendar {
                    max-width: 360px !important;
                    td {
                        padding: 4px;
                    }
                }
            }
            @media (max-width: 1280px) {
                .p-datepicker.p-component {
                    max-width: 100%;
                }
                .p-datepicker-calendar {
                    max-width: 100% !important;
                    td {
                        padding: unset !important;
                    }
                }
            }
        }
        &.max-36 {
            .p-datepicker.p-component {
                max-width: 360px;
            }
            .p-datepicker-calendar {
                max-width: 360px !important;
                td {
                    padding: 4px;
                }
            }
            &.below {
                @media (max-width: 1536px) {
                    .p-datepicker.p-component {
                        bottom: unset !important;
                        top: 44px !important;
                    }
                }
            }
        }
        &.max-fit {
            .p-datepicker.p-component {
                max-width: 336px;
                width: 336px;
            }
            &.below {
                .p-datepicker.p-component {
                    top: 44px !important;
                }
            }
            &.above {
                .p-datepicker.p-component {
                    bottom: 44px !important;
                    top: unset !important;
                }
            }
            &.right-lg {
                @media (max-width: 1280px) {
                    .p-datepicker.p-component {
                        right: 0 !important;
                        left: unset !important;
                    }
                }
            }
            .p-datepicker-calendar {
                max-width: 336px !important;
                td {
                    padding: 0;
                    .p-ripple.p-element {
                        margin: 0;
                    }
                }
            }
            .p-calendar .p-datepicker {
                min-width: fit-content;
            }
            .p-datepicker-header {
                padding: 0;
            }
        }
        &.left-side {
            .p-datepicker.p-component {
                right: 0 !important;
                left: unset !important;
            }
        }
        .text-sm {
            .p-inputtext,
            .p-datepicker table td,
            .p-datepicker table th {
                font-size: 14px !important;
            }
        }
        &.hide-icon {
            .p-calendar {
                background-color: rgba(255, 255, 255, 0.01);
                &>input {
                    background-color: rgba(255, 255, 255, 0.01);
                }
            }
        }
    }
    .custom-textarea {
        .p-inputtextarea {
            min-height: 96px;
            &:hover {
                border: 1px solid #84caff;
            }
            &:focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
            &.is-invalid {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                    border: 1px solid #dc3545;
                }
            }
        }
    }
    .custom-checkbox {
        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #2E90FA;
        }
        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #2E90FA;
            background: #EFF8FF;
        }

        .p-checkbox-label {
            color: #101828;
        }
        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
        }
        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }
        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #1570EF;
            font-weight: 600;
            font-size: 12px;
            transition-duration: 0ms;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #EFF8FF;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #2E90FA;
            box-shadow: none;
        }

        &.invalid {
            .p-checkbox .p-checkbox-box {
                border: 1px solid #d92d20;
            }
            .p-checkbox .p-checkbox-box.p-highlight {
                border-color: #d92d20;
            }
            .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
                border-color: #d92d20;
            }
        }
        .is-invalid {
            .p-checkbox .p-checkbox-box {
                border: 1px solid #d92d20;
            }
            .p-checkbox .p-checkbox-box.p-highlight {
                border-color: #d92d20;
            }
            .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
                border-color: #d92d20;
            }
        }
    }

    .custom-radio {
        .p-radiobutton .p-radiobutton-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;

            &:hover {
                border-color: #1570EF !important;
            }
        }

        .p-radiobutton .p-radiobutton-box.p-highlight {
            background: #EFF8FF;
            border-color: #1570EF;

            &:hover {
                background: #EFF8FF;
            }
        }

        .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
            background-color: #1570EF;
            width: 8px;
            height: 8px;
        }

        .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
            border-color: #1570EF !important;
            background: #EFF8FF !important;
        }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
            border-color: #1570EF;
            background: #EFF8FF;
        }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
            box-shadow: none !important;
        }

        .is-invalid {
            .p-radiobutton .p-radiobutton-box {
                border: 1px solid #d92d20 !important;
            }
        }
    }

    .custom-dropdown {
        .p-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 80px;
            height: 44px;
            padding: 4px;
            border-radius: 8px;
            &:hover {
                border: 1px solid #84caff;
            }
            &.p-dropdown-open,
            &.p-focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
            
            .p-dropdown-item {
                border-radius: 6px;
                color: #344054 !important;
                font-weight: 400 !important;
                &.p-highlight {
                    background-color: #f2f4f7;
                }
            }

            .p-dropdown-items-wrapper {
                padding: 4px;
                font-family: Inter, sans-serif;
                font-size: 14px;
                line-height: 20px;
                scrollbar-width: thin;
                scrollbar-color: #888 #f2f2f2;

                .p-dropdown-items {
                    padding: 0;
                }

                &::-webkit-scrollbar {
                    width: 6px !important;
                }
            }

            .p-dropdown-label {
                min-height: 44px;

                &.p-inputtext {
                    color: #344054;
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                }

                &.p-placeholder {
                    color: #98a2b3;
                    font-weight: 400;
                    min-height: 44px;
                }

                div {
                    justify-content: start;
                }
            }
            .p-dropdown-filter {
                min-height: 44px;
                height: 44px;
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
        }
        &.is-invalid {
            .p-dropdown {
                border: 1px solid #dc3545 !important;
                &:focus {
                    border: 1px solid #dc3545;
                }
                &.p-dropdown-open {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
                }
            }
        }
        &.custom-1 {
            @media (max-width: 1536px) {
                .p-overlay.p-component {
                    right: 0 !important;
                    left: unset !important;
                }
            }
        }
        &.left-side {
            .p-overlay.p-component {
                right: 0 !important;
                left: unset !important;
            }
        }
        &.top-side {
            .p-overlay.p-component {
                bottom: 44px !important;
                top: unset !important;
            }
        }
        &.min-42 {
            .p-overlay.p-component {
               min-width: 420px !important;
            }
        }
        &.phone-custom {
            .p-dropdown-trigger {
                width: 1.25rem !important;
            }
        }
        .p-dropdown.p-component.p-disabled {
            background-color: #F9FAFB !important;
        }
        &.is-disabled {
            .p-dropdown.p-component {
                background-color: #F9FAFB !important;
            }
        }
    }
    .custom-group {
        .p-inputtext.p-component.p-element {
            height: 44px;
            min-height: 44px !important;
            border-radius: 8px 0 0 8px;

            &:hover {
                border: 1px solid #84caff;
            }
            &:focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
            &.is-invalid {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                    border: 1px solid #dc3545;
                }
            }
        }

        .p-dropdown {
            padding: 4px;
            border-radius: 0px 8px 8px 0 !important;
            .p-dropdown-trigger {
                width: 16px;
                padding-right: 16px;
            }
        }
    }

    .custom-switch {
        .p-inputswitch {
            width: 36px;
            height: 20px;
            .p-inputswitch-slider:before {
                width: 16px;
                height: 16px;
                left: 2px;
                margin-top: -8px;
            }
            &.p-inputswitch-checked {
                .p-inputswitch-slider {
                    background: #1570EF;
                }

                .p-inputswitch-slider:before {
                    transform: translateX(16px);
                }
            }
            &.p-focus {
                .p-inputswitch-slider {
                    box-shadow: none;
                }
            }
        }
    }

    .custom-input-number {
        .p-inputnumber {
            width: 100%;
            display: inline-block;
            &:hover {
                border-color:#84caff !important;
            }
        }

        .p-inputnumber-input {
            font-size: 14px;
            color: #344054;
        }

        .is-invalid {
            .p-inputnumber-input {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                    border: 1px solid #dc3545;
                }
            }
        }
    }

    .custom-input-mask {
        &.is-invalid {
            .p-inputmask {
                border: 1px solid #dc3545 !important;
    
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
    
                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }
        }
        .p-inputmask {
            font-size: 14px !important;
            color: #101828;
        }
    }

    .contact-information .p-dropdown {
        width: 100%;
        max-height: 44px;
        border: 1px solid #D0D5DD;
    }
    
    .contact-information .container-phone-input .p-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 40px !important;
        border: 1px solid transparent;
    }
    
    .contact-information .p-dropdown .p-dropdown-label.p-placeholder {
        font-weight: 400;
        color: #98A2B3;
    }

    .dropdown-booking-issue {
        .p-dropdown.p-component {
            height: 36px;
            min-height: 36px;
            width: 100%;
            border-radius: 8px;
            &:hover {
                border: 1px solid #84caff;
            }
            &.p-focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }

            .p-dropdown-label {
                font-size: 14px;
            }
            .p-inputtext {
                display: flex;
                align-items: center;
                min-height: 26px;
                padding: 8px 12px 8px 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 150px;
            }

            .p-dropdown-trigger {
                .p-dropdown-trigger-icon {
                    padding-right: 0;
                    color: #667085;
                }
            }
            .p-dropdown-items {
                padding: 4px 0;
            }
            .p-dropdown-item {
                font-size: 14px;
                color: #344054;
                font-size: 14px;
                padding: 0;
                &:hover {
                    background-color: #eff8ff;
                }
            }

            .p-dropdown-items-wrapper .p-dropdown-item.p-highlight {
                background-color: transparent;
                &:hover {
                    background-color: #eff8ff;
                }
            }
        }
    }

    .dropdown-booking-helpdesk {
        .p-dropdown.p-component {
            height: 36px;
            min-height: 36px;
            width: 100%;
            border-radius: 8px;
            &:hover {
                border: 1px solid #84caff;
            }
            &.p-focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }

            .p-dropdown-label {
                font-size: 14px;
            }
            .p-inputtext {
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                color: #101828;
                display: flex;
                align-items: center;
                min-height: 26px;
                padding: 8px 12px 8px 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 238px;
            }

            .p-dropdown-trigger {
                .p-dropdown-trigger-icon {
                    padding-right: 0;
                    color: #667085;
                }
            }
            .p-dropdown-items {
                padding: 4px 0;
            }
            .p-dropdown-item {
                font-size: 14px;
                color: #344054;
                font-size: 14px;
                padding: 0;
                &:hover {
                    background-color: #eff8ff;
                }
            }

            .p-dropdown-items-wrapper .p-dropdown-item.p-highlight {
                background-color: transparent;
                &:hover {
                    background-color: #eff8ff;
                }
            }
        }
    }

    /* Webkit-based browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #d0d5dd;
        border-radius: 6px !important;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
    
    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #d0d5dd #f1f1f1;
    }
}

/* ------------------------------------ Start calendar filter ------------------------------------ */
.calendar-overlayPanel {
    &.p-overlaypanel {
        &:before {
            display: none;
        }

        &:after {
            display: none;
        }

        .p-overlaypanel-content {
            padding: 0px !important;

        }
    }
}

p-calendar.calendar-dashboard {
    .p-datepicker {
        .p-datepicker-header {
            height: 65px;
        }

        table {
            td {
                padding: unset;

                span {
                    color: #344054;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    box-shadow: none;
                    width: 40px;
                    height: 40px;
                }
            }

            td.p-datepicker-today {
                span {
                    border-color: #1570EF;
                    background-color: unset;
                    border-radius: 100%;
                    color: #1570EF;
                }
            }
        }

        .p-datepicker-calendar td .start-date {
            background-color: #1570EF;
            color: white;
            border-radius: 50%;
        }

        .p-datepicker-calendar td .end-date  {
            background-color: #1570EF;
            color: white;
            border-radius: 50%;
        }

        .p-datepicker-calendar td .in-range {
            background-color: #F2F4F7;
            color: #344054;
            border-radius: 0;
          }
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        border-radius: 50%;
        color: white;
        background-color: #1570EF;
        opacity: 50%;
    }

    .p-monthpicker {
        .p-monthpicker-month {
            width: 22%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 4px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }

    .p-yearpicker {
        .p-yearpicker-year {
            width: 20%;
            height: 32px;
            color: #344054;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 8px 6px;
            box-shadow: unset !important;

            &.p-highlight {
                border: 1px solid #1570EF;
                background-color: unset;
                border-radius: 4px;
                color: #1570EF;
                box-shadow: unset !important;
            }
        }

    }
}
.calendar-container {
    .p-inputtext {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        color: #101828;
        &:enabled:hover {
            border-color: #84CAFF;
        }
    }
    .p-datepicker table td > span.p-highlight{
        background: none;
    }
    .p-inputtext:enabled:focus {
        border-color: #84caff;
        outline: none;
        box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
}

.booking-issue-list {
    .enable-sort{
        background-color: #EFF8FF !important;
        color: #1570EF !important;
      }
}
.calendar-stay-dates {

    .p-inputtext {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        height: 44px !important;
        min-height: 44px !important;
        &:hover {
            border: 1px solid #84caff;
          }
        &:focus {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
        }
    }

    .p-datepicker {
        padding: 0 !important;
        border: 0 !important;
    }

    .p-datepicker table td > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-right: 12px;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-left: 12px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 0;
        padding-right: 0;
    }

    .p-datepicker table th > span {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .p-datepicker table td {
        padding: 0 !important;
    }

    .p-datepicker table td > span.p-highlight {
        color: white;
        background: #1570EF;
        border-radius: 100%;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today > span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td > span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }
    .p-datepicker .p-timepicker button {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-datepicker .p-timepicker span {
        font-size: 1rem;
      }

    .p-datepicker .p-timepicker {
        border-top: 1px solid #dee2e6;
        padding: 0 !important;
    }
    .p-datepicker .p-datepicker-header {
        padding: 0 !important;
    }
    .p-overlaypanel .p-overlaypanel-content {
        padding: 0 !important;
    }
}

// Custom color button clear input type = search
[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
    background-size: 10px 10px;
}

/* ------------------------------------ End calendar filter ------------------------------------ */

/* --------------------------- Start booking issue status note dialog ---------------------------- */
.booking-issue-status-note-dialog {
    p-dropdown {
        width: 100%;
        .p-dropdown {
                width: 100%;
            }
    }
    
}
.discard-changes-dialog {
    .p-dialog-content {
        padding-bottom: 0;
    }
}

/* --------------------------- End booking issue status note dialog ---------------------------- */
